import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import Header from './Header';
import Footer from './Footer';

import '../../assets/plugins/simple-line-icons/simple-line-icons.min.css';
import '../../assets/plugins/bootstrap/css/bootstrap.min.css';

// THEME STYLES -->
import '../../assets/demos/index/css/plugins.css';
import '../../assets/demos/index/css/components.css';
import '../../assets/demos/index/css/purple2.css';

import '../custom.css';

const Layout = props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            description
            keywords
          }
        }
      }
    `}
    render={data => {
      const title = props.title ? `${props.title} | ${data.site.siteMetadata.title}` : data.site.siteMetadata.title;
      const description = props.description; // || data.site.siteMetadata.description;
      const keywords = props.keywords; // || data.site.siteMetadata.keywords;

      const meta = [];
      props.description && meta.push({ name: 'description', content: description });
      props.keywords && meta.push({ name: 'keywords', content: keywords });

      return (
        <div>
          <Helmet
            title={title}
            meta={meta}
            bodyAttributes={{
              class: 'c-layout-header-static c-layout-header-mobile-static'
            }}
          >
            <html lang="en" />

            <link
              href="https://fonts.googleapis.com/css?family=Roboto+Condensed:300italic,400italic,700italic,400,300,700&amp;subset=all"
              rel="stylesheet"
              type="text/css"
            />
            <link rel="shortcut icon" href="/favicon.ico" />
          </Helmet>
          <Header siteTitle={title} />
          <div className="c-layout-page">{props.children}</div>
          <Footer />
        </div>
      );
    }}
  />
);

Layout.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired
};

export default Layout;
