import React, { Component } from 'react';
import { Link, graphql, StaticQuery } from 'gatsby';

import { menu } from '../config';

import Logo from '../../assets/images/logo.png';

class Header extends Component {
  constructor(props) {
    super(props);

    this.handleToggle = this.handleToggle.bind(this);

    this.state = { isToggleOn: false };
  }

  handleToggle() {
    this.setState(state => ({
      isToggleOn: !state.isToggleOn
    }));
  }

  render() {
    // TODO: Refactor!!
    const isHomePage = typeof window !== 'undefined' && window.location.pathname === '/';
    const nonHomePageStyles = isHomePage ? '' : ' c-border-bottom';
    const navClassName = `c-navbar-wrapper clearfix ` + nonHomePageStyles;

    const query = graphql`
      query {
        site {
          siteMetadata {
            title
            phone
            email
          }
        }
      }
    `;

    const TopBar = ({ data }) => (
      <div className="c-topbar c-topbar-light">
        <div className="container">
          <nav className="c-top-menu c-pull-right">
            <ul className="c-icons c-theme-ul">
              <li>
                <i className="icon-envelope" />
              </li>
              <li>
                <a href={`mailto:${data.site.siteMetadata.email}`} target="_blank" rel="noopener noreferrer">
                  {data.site.siteMetadata.email}
                </a>
              </li>
              <li>
                <i className="icon-call-end" />
              </li>
              <li>
                <span>{data.site.siteMetadata.phone}</span>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    );

    const NavBar = ({ data }) => (
      <div className="c-navbar">
        <div className="container">
          <div className={navClassName}>
            <div className="c-brand c-pull-left">
              <Link to="/" className="c-logo">
                <img src={Logo} alt={data.site.siteMetadata.title} className="c-desktop-logo" />
                <img src={Logo} alt={data.site.siteMetadata.title} className="c-desktop-logo-inverse" />
                <img src={Logo} alt={data.site.siteMetadata.title} className="c-mobile-logo" />
              </Link>
              <button
                className="c-hor-nav-toggler"
                type="button"
                data-target=".c-mega-menu"
                onClick={this.handleToggle}
              >
                <span className="c-line" />
                <span className="c-line" />
                <span className="c-line" />
              </button>

              {/* <button className="c-topbar-toggler" type="button" onClick={this.handleToggle}>
                <i className="fa fa-ellipsis-v" />
              </button> */}
            </div>
            <nav
              className={`c-mega-menu c-pull-right c-mega-menu-light c-mega-menu-dark-mobile c-fonts-uppercase c-fonts-bold ${
                this.state.isToggleOn ? 'c-shown' : ''
              }`}
            >
              <ul className="nav navbar-nav c-theme-nav">
                {menu.map((m, idx) => (
                  <li key={idx} className="c-menu-type-classic c-open">
                    <Link
                      to={m.link}
                      activeStyle={{
                        color: '#aa67a3'
                      }}
                      className="c-link dropdown-toggle"
                    >
                      {m.title}
                    </Link>
                    {m.menu && (
                      <ul className="dropdown-menu c-menu-type-classic c-pull-left">
                        {m.menu.map((s, idx) => (
                          <li key={idx}>
                            <Link
                              to={s.link}
                              activeStyle={{
                                color: '#aa67a3'
                              }}
                            >
                              {s.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    );

    return (
      <StaticQuery
        query={query}
        render={data => (
          <div>
            <header
              className="c-layout-header c-layout-header-4 c-layout-header-default-mobile"
              data-minimize-offset="80"
            >
              <TopBar data={data} />
              <NavBar data={data} />
            </header>
          </div>
        )}
      />
    );
  }
}

export default Header;
