import React from 'react';

import Fish from '../../assets/images/fish.png';
import TradeOnlyImage from '../../assets/images/trade-only-white.png';

const styleLi = {
  listStyle: 'none'
};

const styleA = {
  textDecoration: 'none'
};

const ColumnLeft = () => (
  <div className="col-md-6 col-sm-6 col-xs-6">
    <div className="c-container c-left">
      <div className="c-content-title-1">
        <h3 className="c-font-uppercase c-font-bold">Calf Hey Group</h3>
        <div className="c-line-left c-theme-bg" />
      </div>

      <p className="c-about">
        At Calf Hey we are dedicated to providing you, the customer, with a self adhesive labelling service that is{' '}
        <strong>second to none!</strong> From initial contact to the delivery of your labels, we do everything possible
        to make dealing with us a pleasant experience.
      </p>

      <p className="c-margin-t-20">
        Member of the{' '}
        <a href="https://www.britishprint.com/" target="_blank" rel="noopener noreferrer">
          British Printing Industries Federation
        </a>
      </p>

      <p className="c-margin-t-20">
        {new Date().getFullYear()} &copy; Calf Hey Group |<span> All Rights Reserved.</span>
      </p>
      <img className="c-margin-t-20" src={TradeOnlyImage} alt="trade-only-company" width="120px" />
    </div>
  </div>
);

const ColumnRight = () => (
  <div className="col-md-6 col-sm-6 col-xs-6">
    <div className="c-container c-right">
      <div className="c-content-title-1">
        <h3 className="c-font-uppercase c-font-bold">Find Us</h3>
        <div className="c-line-right c-theme-bg" />
      </div>

      <ul className="c-address">
        <li style={styleLi}>
          <i className="icon-pointer" /> Victoria House, Victoria Street
          <br />
          Lockwood, Huddersfield,
          <br />
          HD1 3RG, United Kingdom
          <br />
          <br />
        </li>

        <li style={styleLi}>
          <strong>Calf Hey Design</strong> <br />
          <i className="icon-call-end" /> 01484 542795 <br />
          <i className="icon-envelope" />{' '}
          <a href="mailto:sales@calf-hey.com" target="_blank" style={styleA} rel="noopener noreferrer">
            sales@calf-hey.com
          </a>
          <br />
          <br />
        </li>

        <li style={styleLi}>
          <strong>Calf Hey Rotary</strong> <br />
          <i className="icon-call-end" /> 01484 448033 <br />
          <i className="icon-envelope" />{' '}
          <a href="mailto:chris@calf-hey.co.uk" target="_blank" style={styleA} rel="noopener noreferrer">
            Chris@calf-hey.co.uk
          </a>
        </li>
      </ul>
      <img className="c-margin-t-30" height={50} src={Fish} alt="Fish" />
    </div>
  </div>
);

const Footer = () => (
  <footer className="c-layout-footer c-layout-footer-2 c-option-2">
    <div className="container">
      <div className="row">
        <ColumnLeft />
        <ColumnRight />
      </div>
    </div>
  </footer>
);

export default Footer;
