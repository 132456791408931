export const GOOGLE_MAPS_API_KEY = 'AIzaSyA91nDAS2TnsWaI4TUDf9ka4VnBFquE758';

export const menu = [
  { title: 'Home', link: '/' },
  {
    title: 'Services',
    link: '/services/hotfoil/',
    menu: [
      { title: 'Hotfoil', link: '/services/hotfoil/' },
      { title: 'Thermal', link: '/services/thermal/' },
      { title: 'Digital', link: '/services/digital/' },
      { title: 'Letterpress', link: '/services/letterpress/' }
    ]
  },
  {
    title: 'Products',
    link: '/products/',
    menu: [
      { title: 'Choosing a product', link: '/products/' },
      { title: 'Barcode Labels', link: '/products/barcode-labels/' },
      { title: 'Computer Labels', link: '/products/computer-labels/' },
      { title: 'Domed Labels', link: '/products/domed-labels/' },
      { title: 'Embossed Labels', link: '/products/embossed-labels/' },
      { title: 'Numbered Labels', link: '/products/numbered-labels/' },
      { title: 'Overprinting', link: '/products/overprinted-labels/' },
      { title: 'Plain Labels', link: '/products/plain-labels/' },
      { title: 'Printed Labels', link: '/products/printed-labels/' },
      { title: 'Security Labels', link: '/products/security-labels/' },
      { title: 'Split-lam Labels', link: '/products/split-lam-labels/' },
      { title: 'Window Stickers', link: '/products/window-stickers/' }
    ]
  },
  { title: 'Artwork', link: '/artwork/' },
  {
    title: 'Enquiry',
    link: '/enquiry/',
    menu: [{ title: 'Label Enquiry', link: '/enquiry/' }, { title: 'Sample Pack', link: '/sample/' }]
  },
  {
    title: 'About Us',
    link: '/about/',
    menu: [
      { title: 'About Calf Hey', link: '/about/' },
      { title: 'Meet the Staff', link: '/staff/' },
      { title: 'The Founders', link: '/founders/' },
      { title: 'Privacy Policy', link: '/privacy-policy/' }
    ]
  },
  { title: 'Contact Us', link: '/contact/' }
];
